<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="3" md="3" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2" md="2">
            <v-checkbox
              v-model="generatedFilter"
              label="Generált kuponok"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <!-- <pre>
            {{ statusFilter }}
            </pre> -->
            <v-select
              v-if="statuses.webshop"
              v-model="statusFilter"
              :items="statuses.webshop.coupon"
              :label="$t('FORMS.status')"
              item-text="value"
              item-value="key"
            >
              <template slot="selection" slot-scope="slotProps">
                {{ $t("STATUSES." + slotProps.item.value) }}
              </template>
              <template v-slot:item="slotProps">
                <span class="navi-text">{{
                  $t("STATUSES." + slotProps.item.value)
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredCouponCollection"
          :footer-props="{
            'items-per-page-options': [50, 75, 100],
          }"
          :items-per-page="50"
          :search="search"
          :item-class="itemRowBackground"
          :loading="loadingTable"
          :sort-by="['id']"
          sort-desc="true"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <CouponModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></CouponModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <!-- <pre>{{ filteredCouponCollection }}</pre> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import CouponModalForm, { initialFormData } from "./CouponModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "webshop.coupon.";

export default {
  name: "Coupons",
  components: { CouponModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("COUPONS.coupons"),
      search: "",
      routePath: "/webShop/coupons/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$t("TABLE.created_at_date"),
          value: "date",
        },
        {
          text: this.$t("FORM_INPUT_NAMES.type"),
          value: "type",
        },

        {
          text: this.$t("TABLE.used_count"),
          value: "order_count",
        },
        {
          text: "Email",
          value: "email",
        },
        { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],
      statusFilter: 1,
      generatedFilter: 0,
      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["couponCollection", "statuses"]),

    types() {
      return this.$store.getters.types;
    },

    endPoint() {
      return "webshop/admin/" + SiteService.getActiveSiteId() + "/coupon/";
    },

    filteredCouponCollection() {
      // return this.couponCollection.filter((item) => {
      //   return (
      //     Boolean(item.status) == Boolean(this.statusFilter) &&
      //     Boolean(item.generated) == Boolean(this.generatedFilter)
      //   );
      // });

      // return this.couponCollection.filter((item) => {
      //   return (
      //     1 * item.status == 1 * this.statusFilter &&
      //     Boolean(item.generated) == Boolean(this.generatedFilter)
      //   );
      // });

      return this.couponCollection
        .filter((item) => {
          return (
            1 * item.status == 1 * this.statusFilter &&
            Boolean(item.generated) == Boolean(this.generatedFilter)
          );
        })
        .map((item) => {
          item.date = new Date(item.created_at).toLocaleString("hu-HU", {
            timeZone: "UTC",
          });

          item.order_count = item.orderIds.length;

          if (this.types && this.types.webshop) {
            let couponType = this.types.webshop.coupon.find(
              (i) => i.key == item.type
            );
            if (couponType) {
              item.type =
                this.$t("COUPON_TYPES." + couponType.value) + ": " + item.value;
            }
          }
          // =======
          //           item.type = this.$t(
          //             "FORM_INPUT_NAMES." +
          //               this.types.webshop.coupon.find((i) => i.key == item.type).value
          //           );
          // >>>>>>> origin/VARGA_SUPP_AS0-64
          return item;
        });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchCoupon"]),
    fetchModel() {
      return this.fetchCoupon();
    },

    getStatus(val) {
      if (this.statuses.webshop && this.statuses.webshop.coupon) {
        return this.$t(
          "STATUSES." +
            this.statuses.webshop.coupon.find(
              (item) => item.key == val || (!item.key && !val)
            ).value
        );
      }
    },

    itemRowBackground(item) {
      let backgroundColor = item.orderIds.length > 0 ? "backgroundGreen" : "";
      console.log(backgroundColor);
      return backgroundColor;
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>

<style lang="css">
.backgroundGreen {
  background-color: #e6ffe6;
}
</style>
